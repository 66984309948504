<template>
  <div>
    <ValidationObserver
      v-for="(form, index) in formConfig.form"
      :key="`form-${index})`"
      :ref="`observer-${index})`"
      v-slot="{ handleSubmit }"
    >
      <b-card
        v-if="form.show[formMode]"
        :title="form.formName"
      >
        <template #footer>
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="danger"
            @click="resetForm"
          >
            Reset
          </b-button>
        </template>
        <b-form>
          <b-row>
            <template v-for="(elem, i) in form.elem">
              <b-col
                v-if="!elem.customValidation && elem.show[formMode]"
                :key="`${elem.key}-${i}`"
                :cols="elem.hasOwnProperty('colSize') ? elem.colSize.default : 12"
                :lg="elem.hasOwnProperty('colSize') ? elem.colSize.lg : 6"
              >
                <base-input
                  v-model="actualForm[elem.key]"
                  :vid="actualForm[elem.key]"
                  :rules="elem.rules"
                  :type="elem.type"
                  :name="elem.fieldName"
                  :label="elem.fieldName"
                  :select-option="elem.hasOwnProperty('options') ? elem.options : null"
                />
              </b-col>

              <b-col
                v-if="elem.customValidation && elem.show[formMode]"
                :key="`${elem.key}-${i}`"
                :cols="elem.hasOwnProperty('colSize') ? elem.colSize.default : 12"
                :lg="elem.hasOwnProperty('colSize') ? elem.colSize.lg : 6"
              >

                <div v-if="elem.type === 'switch'">
                  <b-form-checkbox
                    v-model="actualForm[elem.key]"
                    checked="false"
                    :name="elem.fieldName"
                    switch
                    inline
                  >
                    {{ elem.fieldName }}
                  </b-form-checkbox>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { isEmpty } from 'lodash'
import Config from '@/views/administration/users/formConfig'
import BaseInput from '@/leanpay-components/BaseInput.vue'

export default {
  components: {
    ValidationObserver,
    BaseInput,
  },
  data() {
    return {
      actualForm: {
        is_active: false,
      },
      formMode: 'create',
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formTitle() {
      const title = this.formConfig.title[this.formMode]
      return title
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  mounted() {
    this.$store.dispatch('users/getRolesList', this.$route.params.id).then(({ data }) => {
      this.actualForm = data.data
    })

    if (!isEmpty(this.$route.params)) {
      this.formMode = this.$route.params.mode
    }

    if (this.formMode === 'edit'
        || this.formMode === 'remove'
        || this.formMode === 'view') {
      console.log(`${this.formMode} Mode`)
      this.$store.dispatch('users/getUser', this.$route.params.id).then(({ data }) => {
        this.actualForm = data.data
      })
    } else if (this.formMode === 'create') {
      console.log(`${this.formMode} Mode`)
    } else {
      console.log('Unknown Mode')
      this.$router.push({ name: this.parentPageNamedRoute })
    }
  },
  methods: {
    onSubmit() {
      if (this.$route.params.mode === 'edit') {
        const payload = {
          userId: this.$route.params.id,
          // password: this.actualForm.password,
          full_name: this.actualForm.full_name,
          // email: this.actualForm.email,
          phone_number: this.actualForm.phone_number.replaceAll(' ', ''),
          is_active: this.actualForm.is_active,
          // system_id: 1, // 1=merchant
        }
        this.$store.dispatch('users/updateUser', payload)
      } else {
        const payload = {
          password: this.actualForm.password,
          full_name: this.actualForm.full_name,
          email: this.actualForm.email,
          phone_number: this.actualForm.phone_number.replaceAll(' ', ''),
          is_active: this.actualForm.is_active,
          system_id: 1, // 1=merchant
        }
        this.$store.dispatch('users/createUser', payload)
      }
    },
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
    resetForm() {
      requestAnimationFrame(() => {
        console.log(this.$refs.observer)
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
